var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scorecard-form",
    attrs: {
      "id": "scorecard-form-content"
    }
  }, [_c('div', {
    staticClass: "scorecard-form-header content-small"
  }, [_c('span', {
    staticStyle: {
      "width": "64px",
      "margin-left": "108px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UnitPrice")) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("Activity")) + " ")])]), _c('div', {
    staticClass: "scorecard-form-body"
  }, _vm._l(_vm.template.topics, function (topic) {
    return _c('div', {
      key: topic.id,
      staticClass: "topic-row"
    }, [_c('span', {
      staticClass: "topic-title"
    }, [_vm._v(" " + _vm._s(topic.title.et) + " ")]), _vm._l(topic.fields, function (field) {
      return _c('div', {
        key: field.title.et
      }, [!_vm.isEdit ? _c('span', {
        staticClass: "topic-field-row"
      }, [_c('span', {
        staticClass: "topic-unit-price"
      }, [_vm._v(" " + _vm._s(field.unitPrice) + " ")]), _c('span', {
        staticClass: "topic-unit-activity"
      }, [_vm._v(" " + _vm._s(field.title.et) + " ")])]) : _c('span', {
        staticClass: "topic-field-row",
        staticStyle: {
          "padding-left": "96px"
        }
      }, [_c('v-text-field', {
        staticClass: "topic-unit-price",
        attrs: {
          "outlined": "",
          "type": "text"
        },
        model: {
          value: field.unitPrice,
          callback: function ($$v) {
            _vm.$set(field, "unitPrice", $$v);
          },
          expression: "field.unitPrice"
        }
      }), _c('v-text-field', {
        staticClass: "topic-unit-activity",
        attrs: {
          "outlined": "",
          "type": "text"
        },
        model: {
          value: field.title.et,
          callback: function ($$v) {
            _vm.$set(field.title, "et", $$v);
          },
          expression: "field.title.et"
        }
      }), _c('img', {
        staticClass: "remove-button",
        attrs: {
          "src": require("../../assets/images/MinusButton.svg"),
          "alt": ""
        }
      })], 1)]);
    })], 2);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }