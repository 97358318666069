<template>
    <div>
        <button
            name="send-offers-button"
            class="transparent-button"
            style="padding: 8px 12px"
            @click="exportData"
        >
        <div
            v-if="loading"
            style="height: 16px; width: 16px"
            class="loader"
        />
        <img
            v-if="!loading"
            alt=""
            src="../../assets/images/share2.svg"
            style="width: 16px; height: 16px"
        />
        <div
            v-if="!loading"
            class="content-small"
        >
            {{ $t("Export") }}
        </div>
        </button>
        <vue-excel-xlsx
            style="display: none"
            ref="fileInput"
            :data="shouldExport ? rowData : []"
            :columns="columnDefs"
            :file-name="filename"
            :sheet-name="sheetName"
            :file-type="'xlsx'"
        />
    </div>
</template>

<script>
export default {
    name: "ExcelExport",
    props: ["fieldKeys", "fieldValues", "rowData", "filename", "sheetName"],
    data() {
        return {
        loading: false,
        shouldExport: false,
        }
    },
    watch: {
        shouldExport(newVal) {
            if(newVal) {
                this.$nextTick(() => {
                    this.$refs.fileInput.$el.click()
                    this.loading = false
                    this.shouldExport = false
                });
            }
        }
    },
    computed: {
        columnDefs() {
            if (!this.fieldKeys || !this.fieldValues) return null
            return this.fieldKeys.map((field, index) => {
                return {
                    label: this.fieldValues[index], 
                    field: field
                }
            })
        }
    },
    methods: {
        async exportData() {
            if (this.loading) return
            this.loading = true
            this.shouldExport = true
            await this.$emit("getExportData")
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
