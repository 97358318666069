var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "activeView": _vm.activeView,
      "year": _vm.obsYear,
      "month": _vm.obsMonth
    },
    on: {
      "searchInput": function (val) {
        return _vm.searchInput = val;
      },
      "viewChanged": _vm.changeView,
      "monthChanged": function (val) {
        return _vm.obsMonth = val;
      },
      "yearChanged": _vm.changeYear
    }
  }), _c('Content', {
    attrs: {
      "active-view": _vm.activeView,
      "search": _vm.searchInput,
      "month": _vm.month,
      "year": _vm.year,
      "obsYear": _vm.obsYear,
      "obsMonth": _vm.obsMonth
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }