<template>
  <div class="header" style="background-color: white">
    <v-row class="title-row">
      <h3 class="header-title">{{ $t("Scorecard") }}</h3>
    </v-row>
    <div class="view-select-wrapper content-small">
      <div
          :style="(activeView === 'Users') ? activeViewStyle : ''"
          @click="$emit('viewChanged', 'Users')"
          id="usersView"
      >
        {{ $t("Users") }}
      </div>
      <!--
      <div
        :style="(activeView === 'Form') ? activeViewStyle : ''"
        @click="$emit('viewChanged', 'Form')"
        id="formView"
      >
          {{ $t("Form") }}
      </div>
      -->
      <div
          :style="(activeView === 'Scorecard') ? activeViewStyle : ''"
          @click="$emit('viewChanged', 'Scorecard')"
          id="scorecardView"
      >
        {{ $t("Scorecard") }}
      </div>
    </div>
    <HeaderToolbar v-if="activeView === 'Users'" :module="'scorecard'">
      <div style="display: flex; justify-content: space-between; width: 100%; gap: 8px;">
        <div style="display: flex; gap: 8px;">
          <v-text-field
              autocomplete="off"
              outlined
              hide-details
              :id="'search-bar'"
              :placeholder="$t('Search') + '...'"
              prepend-inner-icon="mdi-magnify"
              style="background: white"
              @input="(val) => $emit('searchInput', val)"
          />
          <Dropdown
              :key="'yearsDropdown'"
              :items="years"
              :display-key="'name'"
              :value-key="'name'"
              :selected="{name: year}"
              @itemSelected="(_year) => $emit('yearChanged', _year.name)"
          />
          <template v-if="activeView === 'Users'">
            <GroupOrUserSelect v-model="chosenUsers"/>
          </template>
        </div>
        <div>
          <ExcelExport
              ref="excelExport"
              v-if="activeView === 'Users'"
              :fieldKeys="usersScorecards.fieldsKeys"
              :fieldValues="usersScorecards.fields"
              :rowData="usersScorecards.dataset"
              :filename="$t('Scorecard') + '-' + year"
              sheetName="scorecard"
          />
        </div>
      </div>
    </HeaderToolbar>
  </div>
</template>

<script>
import HeaderToolbar from "@/components/common/HeaderToolbar";
import GroupOrUserSelect from "@/components/FormElements/GroupOrUserSelect";
import {mapActions, mapGetters} from "vuex";
import Dropdown from "@/components/common/Dropdown";
import moment from "moment";
import ExcelExport from "@/components/common/ExcelExport.vue";

export default {
  name: "ScorecardHeader",
  components: {
    HeaderToolbar,
    Dropdown,
    ExcelExport,
    GroupOrUserSelect
  },
  props: ["year", "month", "activeView"],
  data() {
    return {
      chosenUsers: null,
      activeViewStyle:
          "padding-bottom: 26px;\n" +
          "border-bottom: 2px solid black;\n" +
          "font-weight: 600;",
      obsYear: null,
    };
  },
  created() {
    this.obsYear = this.year;
    if (this.user.access.company._id === '5p1EOo4yqjeiYCN3R16w') {
      this.chosenUsers = {
          type: "group",
          value: "1yWVyM8_lA"
      };
    } else {
      this.chosenUsers = this.activeScorecardGroupFilter;
    }
  
  },
  watch: {
    chosenUsers: async function (value) {  
      await this.setActiveScorecardGroupFilter(value)
    }
  },
  methods: {
    ...mapActions(["setActiveScorecardGroupFilter"]),
    async getExportData() {
      this.$refs.excelExport.triggerExport();
    },
    getTodaysDate() {
      return moment().format('MMM-YY');
    }
  },
  computed: {
    ...mapGetters(["user", "usersScorecards", "activeScorecardGroupFilter"]),
    years() {
      const years = []
      for (let y = 2022; y <= this.obsYear; y++) {
        years.push({name: y.toString()})
      }
      return years
    },
    months() {
      return moment.months().map((m, i) => {
        return {
          name: m,
          value: i
        }
      })
    },
    availableMonths() {
      let monthIndex = 12;
      if (this.year === moment().year().toString()) {
        monthIndex = moment().format("M") - 0;
        if (moment().isBefore(moment("04", "DD"))) {
          monthIndex -= 1;
        }
      }

      return moment.months().slice(0, monthIndex).map((m, i) => {
        return {
          name: m,
          value: i + 1
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>

.view-select-wrapper {
  height: 42px;
  padding-left: 104px;
  display: flex;
  gap: 24px;
  align-items: start;
}

#usersView,
#formView,
#scorecardView {
  cursor: pointer;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}
</style>
