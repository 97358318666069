<template>
  <div class="content">
    <div
        v-if="!scorecardTemplate"
        style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
        class="loader"
    />
    <UsersContent
        v-else-if="activeView === 'Users'"
        :searchValue="search"
        :year="obsYear"
        :groupId="groupId"
    />
    <FormContent
        v-else-if="activeView === 'Form'"
    />
    <ScorecardContent
        v-else-if="activeView === 'Scorecard'"
        :year="year"
        :month="month.toString()"
        :obsYear="obsYear"
        :obsMonth="obsMonth.toString()"
    />
  </div>
</template>
<script>
import UsersContent from "@/components/Scorecard/UsersContent.vue";
import FormContent from "@/components/Scorecard/FormContent.vue";
import ScorecardContent from "@/components/Scorecard/ScorecardContent.vue";
import {mapGetters} from "vuex";

export default {
  name: "App",
  components: {
    UsersContent,
    FormContent,
    ScorecardContent
  },
  props: ["activeView", "search", "month", "year", "obsMonth", "obsYear", "groupId"],
  computed: {
    ...mapGetters(["scorecardTemplate"]),
  },
};
</script>

<style scoped>
.content {
  display: flex;
  background-color: #f4f5f7;
  flex-direction: row;
  flex-grow: 1;
  padding: 24px 64px 96px 104px;
}
</style>
