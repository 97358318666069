var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay-detail-panel",
    attrs: {
      "id": "overlay-customer"
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _vm.$emit('closePanel', _vm.scorecard);
      },
      expression: "() => $emit('closePanel', scorecard)"
    }],
    staticClass: "scorecard-wrapper"
  }, [_c('div', {
    staticClass: "scorecard-content"
  }, [_c('div', {
    staticClass: "user-info-wrapper"
  }, [_c('v-row', {
    staticClass: "flex justify-space-between"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.obsUser.name) + " ")]), _vm.haveAccessToEdit ? _c('div', {
    staticClass: "button",
    staticStyle: {
      "margin": "0 2px"
    },
    on: {
      "click": _vm.editScorecard
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])]) : _vm._e()]), _c('p', [_vm._v(_vm._s(_vm.year) + " " + _vm._s(_vm.mappedMonth))])], 1), !_vm.loaded ? _c('div', [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  })]) : _vm._e(), _vm.template && _vm.template.fields && _vm.loaded ? _c('div', {
    key: _vm.scorecardUpdater,
    staticClass: "scorecard-form-body"
  }, [_c('div', {
    staticClass: "scorecard-form-header content-small"
  }, [_c('span', {
    staticStyle: {
      "width": "64px",
      "margin-left": "108px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UnitPrice")) + " ")]), _c('span', {
    staticStyle: {
      "width": "260px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Activity")) + " ")]), _c('span', {
    staticStyle: {
      "width": "70px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Quantity")) + " ")]), _c('span', {
    staticStyle: {
      "width": "48px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Points")) + " ")])]), _vm._l(_vm.template.fields, function (field) {
    return _c('div', {
      key: field.id,
      staticClass: "topic-row"
    }, [_c('span', {
      staticClass: "topic-title"
    }, [_vm._v(" " + _vm._s(field.title) + " ")]), _vm._l(field.criteria, function (criteria) {
      return _c('div', {
        key: criteria.value,
        staticClass: "topic-field-row"
      }, [_c('span', {
        staticClass: "field-unit-price"
      }, [_vm._v(" " + _vm._s(criteria.points ? criteria.points : criteria.thresholds ? _vm.calculatePointsRange(criteria.thresholds) : 0) + " ")]), _c('span', {
        staticClass: "field-unit-activity"
      }, [_c('span', {
        staticClass: "content-normal",
        staticStyle: {
          "z-index": "100"
        }
      }, [_vm.showDetails(criteria) ? _c('v-tooltip', {
        attrs: {
          "bottom": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function (_ref) {
            var on = _ref.on;
            return [_c('span', _vm._g({
              staticClass: "hovered-span"
            }, on), [_vm._v(_vm._s(criteria.name))])];
          }
        }], null, true)
      }, [_c('span', {
        domProps: {
          "innerHTML": _vm._s(_vm.showDetails(criteria))
        }
      })]) : _c('span', [_vm._v(_vm._s(criteria.name))])], 1), criteria.commenting ? _c('div', {
        staticClass: "field-unit-add-widget-comment"
      }, [_c('img', {
        staticStyle: {
          "width": "20px",
          "height": "20px"
        },
        attrs: {
          "src": require("@/assets/images/chat-no-bubble.svg"),
          "alt": ""
        }
      }), !_vm.editComment[field._id + '_' + criteria._id] && !criteria.comment ? _c('div', {
        on: {
          "click": function () {
            return _vm.openEditComment(field, criteria);
          }
        }
      }, [_c('p', {
        staticClass: "link"
      }, [_vm._v(" + " + _vm._s(_vm.$t("addComment").charAt(0).toUpperCase() + _vm.$t("addComment").slice(1)) + " ")])]) : _vm._e(), _vm.editComment[field._id + '_' + criteria._id] ? _c('textarea', {
        directives: [{
          name: "click-outside",
          rawName: "v-click-outside",
          value: function () {
            return _vm.closeEditComment(field, criteria);
          },
          expression: "() => closeEditComment(field, criteria)"
        }, {
          name: "model",
          rawName: "v-model",
          value: criteria.comment,
          expression: "criteria.comment"
        }],
        ref: "commentbox",
        refInFor: true,
        staticClass: "task-textarea content-normal",
        staticStyle: {
          "white-space": "pre-wrap"
        },
        attrs: {
          "id": "textarea",
          "rows": "5"
        },
        domProps: {
          "value": criteria.comment
        },
        on: {
          "input": function ($event) {
            if ($event.target.composing) return;
            _vm.$set(criteria, "comment", $event.target.value);
          }
        }
      }) : _vm._e(), _vm._v(" "), criteria.comment && !_vm.editComment[field._id + '_' + criteria._id] ? _c('div', {
        staticClass: "content-normal comment-block",
        on: {
          "click": function () {
            return _vm.openEditComment(field, criteria);
          }
        }
      }, [_vm._v(" " + _vm._s(criteria.comment) + " ")]) : _vm._e()]) : _vm._e()]), _vm.edit && criteria.editable ? _c('span', [_c('v-text-field', {
        staticClass: "field-unit-price",
        attrs: {
          "outlined": "",
          "type": "number"
        },
        on: {
          "input": _vm.calculateMonthSummary
        },
        model: {
          value: criteria.userQuantity,
          callback: function ($$v) {
            _vm.$set(criteria, "userQuantity", $$v);
          },
          expression: "criteria.userQuantity"
        }
      })], 1) : _c('span', {
        staticClass: "field-number"
      }, [_vm._v(" " + _vm._s(criteria.userQuantity ? _vm.formatNumber(criteria) : 0) + " ")]), _c('span', {
        staticClass: "field-number"
      }, [_vm._v(" " + _vm._s(_vm.calculateTotal(criteria)) + " ")])]);
    })], 2);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "scorecard-footer"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Total")) + ": " + _vm._s(_vm.monthSummary))]), _vm.edit ? _c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "background": "black !important",
      "color": "white"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/checkmarkCircled.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")])]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }