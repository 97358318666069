var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-wrapper",
    attrs: {
      "id": "admin-content"
    }
  }, [!_vm.loaded ? _c('div', [!_vm.loaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  }) : _vm._e()]) : _vm.filteredUsers && _vm.filteredUsers.length < 1 ? _c('div', {
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("NoMatchesFound")))])]) : _vm._e(), _vm.filteredUsers && _vm.filteredUsers.length >= 1 && _vm.loaded ? _c('Table', {
    attrs: {
      "fields": _vm.tableFields,
      "dataset": _vm.filteredUsers,
      "add-gap": true,
      "update-key": _vm.updateTable,
      "sort-again": _vm.sortUpdater,
      "scorecard-view": true,
      "roundingDigits": 0
    },
    on: {
      "scorecardClicked": _vm.scorecardClicked
    }
  }) : _vm._e(), _vm.scorecardSidepanel && _vm.isMonthValid ? _c('ScorecardSidepanel', {
    attrs: {
      "user-scorecard": _vm.userScorecard,
      "obsUser": _vm.obsUser,
      "year": _vm.year,
      "month": _vm.obsMonth
    },
    on: {
      "closePanel": _vm.closeScorecardPanel,
      "update-month-summary": _vm.handleMonthSummaryUpdate
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }