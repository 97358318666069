<template>
  <div id="scorecard-scorecard-content" v-if="loaded">
    <div class="scorecard-header" style="margin-bottom: 16px">
      <div class="scorecard-header-left">
        <div
          style="font-size: 18px; line-height: 24px; display: flex; gap: 12px"
        >
          {{ $t("SubmitDate") }}
          <div style="font-weight: 600" v-if="scorecardDeadline">
            {{ formatDate(scorecardDeadline) }}
          </div>
        </div>
        <div>
          {{ $t("Status") }}:
          <span v-if="scorecardSubmitted" style="color: #0aaf60">
            {{ $t("Submitted") }}
          </span>
          <span v-else style="color: #ff1e24">
            {{ $t("NotSubmitted") }}
          </span>
        </div>
      </div>
      <div class="scorecard-header-right">
        <div class="active-scorecard">{{ activeScorecardDisplay }}</div>
        <div class="radio-btn-wrapper" v-if="isBetweenPreviousMonthDeadline">
          <BaseRadioGroup
            v-model="selectedMonth"
            :items="selectableScorecards"
            display-key="name"
            display-value="value"
          />
        </div>
      </div>
    </div>
    <div style="position: relative; width: 835px">
      <div
        v-if="isLoading"
        style="position: absolute; top: 0%; left: 50%; margin: 20px 0"
        class="loader"
      ></div>
      <div class="scorecard-form" v-else>
        <div class="scorecard-form-header content-small">
          <span style="width: 106px; margin-left: 91px">
            {{ $t("UnitPrice") }}
          </span>
          <span style="width: 408px">
            {{ $t("Activity") }}
          </span>
          <span>
            {{ $t("Quantity") }}
          </span>
        </div>
        <div v-if="template && template.fields" class="scorecard-form-body">
          <div
            v-for="field in template.fields"
            :key="field.id"
            class="topic-row"
          >
            <div class="topic-title">
              {{ field.title }}
            </div>
            <div v-for="criteria in field.criteria" :key="criteria.value">
              <div class="topic-field-row">
                <div class="topic-field-row-content">
                  <span
                    style="
                      min-width: 56px;
                      height: 24px;
                      font-size: 18px;
                      line-height: 24px;
                      font-family: Inter, sans-serif;
                    "
                    >{{
                      criteria.points
                        ? criteria.points
                        : criteria.thresholds
                        ? calculatePointsRange(criteria.thresholds)
                        : 0
                    }}
                  </span>
                  <div class="topic-field-row-content-info">
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        width: 376px;
                      "
                    >
                      <span
                        class="content-normal"
                        :class="{
                          'title-padding':
                            !field.allowComments ||
                            (deadlineExpired &&
                              !field.editComment &&
                              !field.editComment),
                          'title-top-padding':
                            field.allowComments &&
                            (field.editComment ||
                              field.comment ||
                              !deadlineExpired),
                        }"
                      >
                        <v-tooltip right v-if="showDetails(criteria)">
                          <template v-slot:activator="{ on }">
                            <span class="hovered-span" v-on="on">{{
                              criteria.name
                            }}</span>
                          </template>
                          <span v-html="showDetails(criteria)"></span>
                        </v-tooltip>
                        <span v-else>{{ criteria.name }}</span>
                      </span>
                      <div
                        v-if="criteria.commenting"
                        style="
                          padding-bottom: 16px;
                          display: flex;
                          flex-wrap: nowrap;
                        "
                      >
                        <div
                          v-if="
                            !editComment[field._id + '_' + criteria._id] &&
                            !criteria.comment
                          "
                          @click="openEditComment(field, criteria)"
                          style="display: flex; flex-wrap: nowrap; gap: 8px"
                        >
                          <img
                            height="16"
                            width="16"
                            :src="require('@/assets/images/chat-no-bubble.svg')"
                            alt="icon"
                          />
                          <div class="link-small" style="font-weight: 600">
                            +
                            {{
                              $t("addComment").charAt(0).toUpperCase() +
                              $t("addComment").slice(1)
                            }}
                          </div>
                        </div>
                        <div
                          v-if="editComment[field._id + '_' + criteria._id]"
                          style="display: flex; gap: 8px; width: 100%"
                          class="comment-text-area"
                        >
                          <img
                            height="16"
                            width="16"
                            :src="require('@/assets/images/chat-no-bubble.svg')"
                            alt="icon"
                          />
                          <textarea
                            v-click-outside="
                              () => closeEditComment(field, criteria)
                            "
                            v-model="criteria.comment"
                            ref="commentBox"
                            @focus="
                              isFocused[field._id + '_' + criteria._id] = true
                            "
                            class="task-textarea content-normal"
                            id="textarea"
                            rows="5"
                          ></textarea>
                        </div>
                        <div
                          v-if="
                            !editComment[field._id + '_' + criteria._id] &&
                            criteria.comment
                          "
                          style="display: flex; gap: 8px; width: 100%"
                          class="comment-text-area"
                        >
                          <img
                            height="16"
                            width="16"
                            :src="require('@/assets/images/chat-no-bubble.svg')"
                            alt="icon"
                          />
                          <div
                            @click="openEditComment(field, criteria)"
                            class="content-small comment-block"
                          >
                            {{ criteria.comment }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; gap: 32px; flex-wrap: nowrap">
                      <div style="width: 116px">
                        <span
                          v-if="!criteria.editable"
                          style="padding-left: 16px"
                        >
                          {{
                            criteria.userQuantity ? formatNumber(criteria) : 0
                          }}
                        </span>
                        <div
                          v-else
                          style="
                            padding: 4px 12px 4px 4px;
                            display: flex;
                            gap: 4px;
                            align-items: center;
                            max-height: 52px;
                          "
                        >
                          <v-text-field
                            v-model="criteria.userQuantity"
                            outlined
                            type="number"
                            class="field-unit-price"
                            @input="calculateMonthSummary"
                          />
                          <div
                            class="value-update-arrows"
                            style="
                              display: flex;
                              cursor: pointer;
                              flex-direction: column;
                            "
                          >
                            <img
                              @click="incrementField(criteria)"
                              height="20"
                              width="20"
                              :src="require('@/assets/images/chevron-up.svg')"
                              alt="inc"
                            />
                            <img
                              @click="decrementField(criteria)"
                              height="20"
                              width="20"
                              :src="require('@/assets/images/chevron-down.svg')"
                              alt="dec"
                            />
                          </div>
                        </div>
                      </div>
                      <div style="display: flex; align-items: center">
                        <p class="content-semibold">
                          {{ calculateTotal(criteria) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="separator" />
        <div class="buttons-container">
          <div class="scorecard-month-summary">
            {{ $t("Total") }}: {{ monthSummary }}
          </div>

          <div class="flex justify-end" style="gap: 8px">
            <button
              v-if="!deadlineExpired"
              @click="save(false)"
              class="transparent-button"
              style="background: black !important; color: white"
            >
              {{ $t("Save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
    class="loader"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import axios from "axios";
import BaseRadioGroup from "@/components/common/BaseRadioGroup.vue";

export default {
  name: "ScorecardContent",
  components: { BaseRadioGroup },
  props: ["year", "month", "obsYear", "obsMonth"],
  data() {
    return {
      loaded: false,
      obsField: null,
      template: null,
      knownValues: null,
      monthSummary: 0,
      submitted: false,
      scorecardSubmitted: false,
      monthStartDate: null,
      scorecardTemplate: [],
      scorecardDeadline: null,
      editComment: {},
      isFocused: {},
      activeScorecard: null,
      selectedMonth: "current",
      currentScorecard: null,
      isLoading: false,
      previousScorecardDeadline: null,
      isSticky: false,
      headerTop: 0,
    };
  },
  async created() {
    this.monthStartDate = moment(
      this.month + "-" + this.year,
      "M-YYYY"
    ).format();
    await this.mapTemplateToScorecard();
  },
  async mounted() {
    this.activeScorecard = this.obsMonth;
    this.$nextTick(() => {
      this.headerTop = this.$el.querySelector('.scorecard-header').getBoundingClientRect().top;
      window.addEventListener('scroll', this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    selectedMonth(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.activeScorecard = newVal;
        this.updateScorecard();
      }
    },
  },
  computed: {
    ...mapGetters(["user", "users"]),
    deadlineExpired() {
      return !(this.year === this.obsYear && this.month === this.obsMonth);
    },
    deadlineDate() {
      const now = moment();
      const deadlineDay = this.template?.deadline?.day || 3;
      const deadlineTime = this.template?.deadline?.time || "23:59";

      return moment().set({
        year: now.year(),
        month: now.month(),
        date: deadlineDay,
        hour: parseInt(deadlineTime.split(":")[0]),
        minute: parseInt(deadlineTime.split(":")[1]),
        second: 0,
      }).add(now.date() < deadlineDay ? 1 : 0, "month");
    },
    isBetweenPreviousMonthDeadline() {
      const now = moment();
      return now.isBefore(this.deadlineDate);
    },
    selectableScorecards() {
      const today = moment();
      const currentMonthName = today.format("MMMM");
      const previousMonthName = today.subtract(1, "month").format("MMMM");

      if (today.isSameOrBefore(this.deadlineDate)) {
        return [
          { name: this.$t(previousMonthName), value: "previous" },
          { name: this.$t(currentMonthName), value: "current" },
        ];
      } else {
        return [{ name: this.$t(currentMonthName), value: "current" }];
      }
    },
    activeScorecardDisplay() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const today = moment();
      const currentMonth = today.month();
      let year = today.year();
      let monthIndex;
      if (this.selectedMonth === "current") {
        monthIndex = currentMonth;
      } else if (this.selectedMonth === "previous") {
        monthIndex = currentMonth - 1;
        if (monthIndex < 0) {
          monthIndex = 11;
          year -= 1;
        }
      } else {
        monthIndex = currentMonth;
      }
      const monthName = this.$t(monthNames[monthIndex]);
      return `${monthName} ${year}`;
    },
  },
  methods: {
    ...mapActions(["setUserScorecards"]),
    handleScroll() {
      const distanceFromTop = window.scrollY + this.headerTop;
      this.isSticky = distanceFromTop > window.innerHeight;
    },
    showDetails(criteria) {
      if (criteria.value === "monthlyRevenue") {
        return [
          "kuu käive üle 50 000 EUR - 150 punkti",
          "kuu käive üle 40 000 EUR - 120 punkti",
          "kuu käive üle 30 000 EUR - 100 punkti",
          "kuu käive üle 20 000 EUR - 80 punkti",
          "kuu käive üle 10 000 EUR - 60 punkti",
          "kuu käive üle 8 000 EUR - 55 punkti",
          "kuu käive üle 6 500 EUR - 50 punkti",
          "kuu käive üle 5 000 EUR - 40 punkti",
          "kuu käive üle 3 500 EUR - 30 punkti",
          "kuu käive üle 2 500 EUR - 20 punkti",
          "kuu käive üle 2 000 EUR - 4 punkti",
          "kuu käive üle 1 500 EUR - 3 punkti",
          "kuu käive üle 1 000 EUR - 2 punkti",
        ].join("<br>");
      }
      return null;
    },
    calculatePointsRange(thresholds) {
      if (!thresholds || thresholds.length === 0) {
        return null;
      }

      let minPoints = thresholds[0].points;
      let maxPoints = thresholds[0].points;

      thresholds.forEach((threshold) => {
        if (threshold.points < minPoints) {
          minPoints = threshold.points;
        }
        if (threshold.points > maxPoints) {
          maxPoints = threshold.points;
        }
      });

      return `${minPoints}-${maxPoints}`;
    },
    incrementField(field) {
      field.userQuantity = parseFloat(field.userQuantity);
      field.userQuantity += 1.0;
      this.restrictValue(field);

      this.calculateMonthSummary();
    },
    decrementField(field) {
      if (field.userQuantity > 0) {
        field.userQuantity = parseFloat(field.userQuantity);
        field.userQuantity -= 1.0;
        this.restrictValue(field);
        this.calculateMonthSummary();
      }
    },
    restrictValue(field) {
      if (
        field.maxPoints &&
        field.userPoints &&
        parseInt(field.userPoints) > field.maxPoints
      ) {
        field.userPoints = field.maxPoints.toString();
        field.points += 1.0;
        field.points -= 1.0;
        this.calculateMonthSummary();
      }
    },
    formatTotal(number) {
      const decimalsArray = number.toString().split(".");
      if (decimalsArray[1] && decimalsArray[1].length >= 2) {
        number = parseFloat(number.toFixed(2));
      }
      return number;
    },
    openEditComment(field, criteria) {
      if (this.submitted || this.deadlineExpired) return;
      let uniqueKey = field._id + "_" + criteria._id;
      this.$set(this.editComment, uniqueKey, true);
    },
    async closeEditComment(field, criteria) {
      let uniqueKey = field._id + "_" + criteria._id;
      if (this.editComment[uniqueKey] !== undefined) {
        this.editComment[uniqueKey] = false;
      }
      await this.save(false);
    },

    formatDate(date) {
      return moment(date).format("DD.MM HH:mm");
    },
    processTemplateAndScorecard(template, scorecard) {
      template.fields.forEach((field) => {
        field.criteria.forEach((criteria) => {
          const score = scorecard.scores.find(
            (s) => s.value === criteria.value
          );
          if (score) {
            criteria.comment = score.comment ? score.comment : "";
            criteria.userPoints = score.points;
            criteria.userQuantity = score.quantity;
          } else {
            criteria.comment = "";
            criteria.userPoints = 0;
            criteria.userQuantity = 0;
          }
        });
      });
      this.processedTemplate = template;
      this.monthSummary = scorecard.monthlySummary || 0;
      this.scorecardSubmitted = scorecard.submitted;
    },
    async updateScorecard() {
      this.isLoading = true;
      await this.mapTemplateToScorecard();
      this.isLoading = false;
    },
    async mapTemplateToScorecard() {
      this.isLoading = true;
      try {
        const templateResponse = await axios.get(`/api/scorecard/template`);
        this.template = templateResponse.status === 200 ? templateResponse.data[0] : null;
        const today = moment();
        let scorecardYear = today.year();
        let scorecardMonth = today.month();
        if (this.selectedMonth === 'previous') {
          let previousMonth = today.subtract(1, 'months');
          scorecardYear = previousMonth.year();
          scorecardMonth = previousMonth.month();
        }

        const deadlineDay = this.template?.deadline?.day || 3;
        const deadlineTime = this.template?.deadline?.time || '23:59';
        let deadlineDate = moment({
          year: scorecardYear,
          month: scorecardMonth,
          day: deadlineDay,
          hour: parseInt(deadlineTime.split(":")[0]),
          minute: parseInt(deadlineTime.split(":")[1]),
          second: 0
        }).add(1, 'months');

        let scorecardResponse = await axios.get(`/api/scorecard/user/${this.user._id}/${scorecardYear}/${scorecardMonth + 1}`);
        let scorecardToUse = scorecardResponse.status === 200 ? scorecardResponse.data[0] : null;

        if (scorecardToUse && this.template) {
          this.processTemplateAndScorecard(this.template, scorecardToUse);
          this.scorecardDeadline = deadlineDate;
        } else {
          console.error("Failed to fetch the selected scorecard or template");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
      this.isLoading = false;
      this.loaded = true;
    },
    async save(isSubmit = false) {
      const today = moment();
      let month;
      let year;
      if (this.selectedMonth === "previous") {
        let previousMoment = today.subtract(1, "months");
        month = previousMoment.month() + 1;
        year = previousMoment.year();
      } else {
        month = today.month() + 1;
        year = today.year();
      }
      let transformedScores = this.template.fields.flatMap((field) =>
        field.criteria.map((criteria) => ({
          id: criteria._id,
          value: criteria.value,
          points: criteria.userPoints,
          quantity: criteria.userQuantity,
          comment: criteria.comment,
        }))
      );
      let payload = {
        scores: transformedScores,
        monthlySummary: this.monthSummary,
      };
      if (isSubmit) {
        payload.submitted = true;
      }
      let response = await axios.post(
        `/api/scorecard/update-scorecard/${this.user._id}/${this.user.access.company._id}/${year}/${month}`,
        { userScorecard: payload }
      );
      if (response.status === 200) {
        this.toastSuccess(
          this.$t(isSubmit ? "ScorecardSubmitted" : "ScorecardSaved")
        );
        if (isSubmit) {
          this.scorecardSubmitted = true;
        }
      } else {
        this.toastError(this.$t("ErrorSavingScorecard"));
      }
    },
    calculateMonthSummary() {
      this.monthSummary = this.template.fields.reduce((totalSum, field) => {
        return (
          totalSum +
          field.criteria.reduce((criteriaSum, criteria) => {
            return criteriaSum + this.calculateTotal(criteria);
          }, 0)
        );
      }, 0);
      this.monthSummary = Math.round(this.monthSummary * 100) / 100;
    },
    calculateTotal(field) {
      if (field.editable) {
        let total = field.points * field.userQuantity;

        if (field && field.maxPoints) {
          const maxTotal = field.maxPoints;
          if (total > maxTotal) {
            total = maxTotal;
          }
        }
        field.userPoints = total;
        return this.formatTotal(field.userPoints);
      } else {
        return Number(field.userPoints) || 0;
      }
    },
    formatNumber(value) {
      let parts = value.userQuantity.toString().split(".");
      let integerPart = parts[0];
      let decimalPart = parts.length > 1 ? "." + parts[1] : "";

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      let formattedNumber = integerPart + decimalPart;
      return value.suffix
        ? `${formattedNumber} ${value.suffix}`
        : formattedNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";
.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.scorecard-header-right {
  display: flex;
  gap: 16px;
  align-items: center;
}

.active-scorecard {
  font-size: 16px;
  font-weight: 500;
}

.scorecard-header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
  margin-bottom: 16px;
  background-color: #f4f5f7;
  padding-top: 8px;
  padding-bottom: 8px;
}

.radio-btn-wrapper {
  background-color: white;
  border-radius: 8px;
}

.comment-block {
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  background: $light-grey;
  padding: 3px 7px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.hovered-span {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 4px;
  }
}

.field-unit-add-widget-comment {
  display: flex;
  gap: 8px;
  margin-top: 8px;

  p {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    cursor: pointer;
  }
}

.scorecard-month-summary {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
  gap: 8px;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.buttons-container {
  width: 100%;
  padding: 32px 8px 16px;
}

.separator {
  margin-left: -24px;
  width: 835px;
  background: $grey;
  height: 2px;
}

::v-deep .v-input__slot {
  min-height: 44px !important;
  font-size: 14px;
  margin-bottom: 0 !important;
}

::v-deep .v-text-field__details {
  display: none;
}

.field-unit-activity {
  width: 450px;
  margin-top: 12px;
}

.field-unit-price {
  width: 80px;
  max-width: 80px;
  max-height: 44px;
}

.remove-button {
  width: 24px;
  height: 24px;
  align-self: center;
  margin-bottom: 10px;
  margin-right: 12px;
  cursor: pointer;
}

.topic-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}

.comment-text-area img {
  margin-top: 4px;
  min-width: 16px;
}

.topic-field-row {
  padding-left: 80px;
  display: flex;
  min-height: 52px;
}

.topic-field-row-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 48px;
  width: 100%;
}

.topic-field-row-content-info {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  min-height: 24px;
}

.topic-row {
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid $grey500;
}

.topic-row:last-of-type {
  border-bottom: 0;
}

.scorecard-form-header {
  padding: 4px 0 8px 0;
  border-bottom: 1px solid #000;
  position: sticky;
}

.title-padding {
  padding: 16px 0;
}

.title-top-padding {
  padding-top: 16px;
}

.scorecard-form {
  background-color: white;
  width: 835px;
  padding: 24px;
  border-radius: 8px;
  position: relative;
}

.value-update-arrows img:hover {
  background-color: $grey;
  border-radius: 4px;
}
</style>
