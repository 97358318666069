var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "radio-group-container"
  }, _vm._l(_vm.items, function (item) {
    return _c('button', {
      key: 'radio-group-' + item[_vm.displayValue],
      class: item.value === _vm.value ? 'radio-group-item-selected' : '',
      on: {
        "click": function ($event) {
          return _vm.buttonClicked(item);
        }
      }
    }, [_c('span', {
      class: item.value === _vm.value ? 'content-small-bold' : 'content-small'
    }, [_vm._v(_vm._s(item[_vm.displayKey]))])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }