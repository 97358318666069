<template>
  <div class="radio-group-container">
    <button @click="buttonClicked(item)" v-for="item of items" :key="'radio-group-' + item[displayValue]" :class="item.value === value ? 'radio-group-item-selected' : ''">
      <span :class="item.value === value ? 'content-small-bold' : 'content-small'">{{ item[displayKey] }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "BaseRadioGroup",
  props: ['value', 'items', 'displayKey', 'displayValue'],
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  methods: {
    buttonClicked(item) {
      if (item.value === this.value) return;
      this.$emit('valueChanged', item.value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.radio-group-container {
  display: flex;
  flex-wrap: nowrap;
  height: 28px;
}

.radio-group-container button {
  all: unset;
  padding: 5px 7px 5px 8px;
  border-top: 1px solid $grey500;
  border-bottom: 1px solid $grey500;
  border-right: 1px solid $grey500;
  cursor: pointer;
}

.radio-group-container button:hover {
  background: $grey;
}

.radio-group-container button:first-of-type {
  padding: 5px 7px;
  border-left: 1px solid $grey500;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.radio-group-container button:last-of-type {
  border-right: 1px solid $grey500;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.radio-group-container button span {
  white-space: nowrap;
}

.radio-group-item-selected {
  background: $grey !important;
}
</style>