<template>
  <div id="scorecard-form-content" class="scorecard-form">
    <div class="scorecard-form-header content-small">
      <span style="width: 64px; margin-left: 108px">
        {{ $t("UnitPrice") }}
      </span>
      <span>
        {{ $t("Activity") }}
      </span>
    </div>
    <div class="scorecard-form-body">
      <div v-for="topic in template.topics" :key="topic.id" class="topic-row">
        <span class="topic-title">
          {{ topic.title.et }}
        </span>
        <div v-for="field in topic.fields" :key="field.title.et">
          <span v-if="!isEdit" class="topic-field-row">
            <span class="topic-unit-price">
              {{ field.unitPrice }}
            </span>
            <span class="topic-unit-activity">
              {{ field.title.et }}
            </span>
          </span>

          <span v-else class="topic-field-row" style="padding-left: 96px">
            <v-text-field
              v-model="field.unitPrice"
              outlined
              type="text"
              class="topic-unit-price"
            />
            <v-text-field
              v-model="field.title.et"
              outlined
              type="text"
              class="topic-unit-activity"
            />
            <img
              src="../../assets/images/MinusButton.svg"
              class="remove-button"
              alt=""
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "FormContent",
  components: {},
  props: ["saveTemplate"],
  data() {
    return {
      isEdit: false,
      template: {
        topics: [],
        createdAt: null,
        dateChanged: null,
      },
    };
  },
  created() {
    if (this.scorecardTemplate) {
      this.template = this.scorecardTemplate;
    }
  },
  mounted() {},
  watch: {
    saveTemplate() {
      this.save();
    },
  },
  computed: {
    ...mapGetters(["user", "users", "scorecardTemplate"]),
  },
  methods: {
    save() {
      this.mapScoreCardTemplate();

      console.log("TODO save template", this.template);
    },
    mapScoreCardTemplate() {
      const date = moment().format();
      if (!this.template.createdAt) {
        this.template.createdAt = date;
      }
      this.template.dateChanged = date;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

::v-deep .v-input__slot {
  min-height: 44px !important;
  font-size: 14px;
}

::v-deep .v-text-field__details {
  display: none;
}

.topic-unit-activity {
}

.topic-unit-price {
  width: 64px;
  max-width: 64px;
  min-width: 64px;
  display: inline-block;
}

.remove-button {
  width: 24px;
  height: 24px;
  align-self: center;
  margin-bottom: 10px;
  margin-right: 12px;
  cursor: pointer;
}

.topic-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 12px;
}

.scorecard-form-body {
}

.topic-field-row {
  padding-left: 108px;
  margin: 8px 0;
  display: flex;
  gap: 24px;
}

.topic-row {
  padding: 12px 0;
  width: 100%;
  border-bottom: 1px solid $grey500;
}

.topic-row:last-of-type {
  border-bottom: 0;
}

.scorecard-form-header {
  padding: 4px 0 8px 0;
  border-bottom: 1px solid #000;
  gap: 24px;
}

.scorecard-form {
  background-color: white;
  width: 835px;
  padding: 24px;
  border-radius: 8px;
}
</style>
