var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t("Scorecard")))])]), _c('div', {
    staticClass: "view-select-wrapper content-small"
  }, [_c('div', {
    style: _vm.activeView === 'Users' ? _vm.activeViewStyle : '',
    attrs: {
      "id": "usersView"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('viewChanged', 'Users');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Users")) + " ")]), _c('div', {
    style: _vm.activeView === 'Scorecard' ? _vm.activeViewStyle : '',
    attrs: {
      "id": "scorecardView"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('viewChanged', 'Scorecard');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Scorecard")) + " ")])]), _vm.activeView === 'Users' ? _c('HeaderToolbar', {
    attrs: {
      "module": 'scorecard'
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "width": "100%",
      "gap": "8px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "placeholder": _vm.$t('Search') + '...',
      "prepend-inner-icon": "mdi-magnify"
    },
    on: {
      "input": function (val) {
        return _vm.$emit('searchInput', val);
      }
    }
  }), _c('Dropdown', {
    key: 'yearsDropdown',
    attrs: {
      "items": _vm.years,
      "display-key": 'name',
      "value-key": 'name',
      "selected": {
        name: _vm.year
      }
    },
    on: {
      "itemSelected": function (_year) {
        return _vm.$emit('yearChanged', _year.name);
      }
    }
  }), _vm.activeView === 'Users' ? [_c('GroupOrUserSelect', {
    model: {
      value: _vm.chosenUsers,
      callback: function ($$v) {
        _vm.chosenUsers = $$v;
      },
      expression: "chosenUsers"
    }
  })] : _vm._e()], 2), _c('div', [_vm.activeView === 'Users' ? _c('ExcelExport', {
    ref: "excelExport",
    attrs: {
      "fieldKeys": _vm.usersScorecards.fieldsKeys,
      "fieldValues": _vm.usersScorecards.fields,
      "rowData": _vm.usersScorecards.dataset,
      "filename": _vm.$t('Scorecard') + '-' + _vm.year,
      "sheetName": "scorecard"
    }
  }) : _vm._e()], 1)])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }