var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [!_vm.scorecardTemplate ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  }) : _vm.activeView === 'Users' ? _c('UsersContent', {
    attrs: {
      "searchValue": _vm.search,
      "year": _vm.obsYear,
      "groupId": _vm.groupId
    }
  }) : _vm.activeView === 'Form' ? _c('FormContent') : _vm.activeView === 'Scorecard' ? _c('ScorecardContent', {
    attrs: {
      "year": _vm.year,
      "month": _vm.month.toString(),
      "obsYear": _vm.obsYear,
      "obsMonth": _vm.obsMonth.toString()
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }