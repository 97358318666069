<template>
  <div id="admin-content" class="main-wrapper">
    <div v-if="!loaded">
      <div
        style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
        v-if="!loaded"
        class="loader"
      />
    </div>
    <div
      v-else-if="filteredUsers && filteredUsers.length < 1"
      style="padding-top: 24px"
    >
      <h3>{{ $t("NoMatchesFound") }}</h3>
    </div>

    <Table
      v-if="filteredUsers && filteredUsers.length >= 1 && loaded"
      :fields="tableFields"
      :dataset="filteredUsers"
      :add-gap="true"
      :update-key="updateTable"
      :sort-again="sortUpdater"
      :scorecard-view="true"
      @scorecardClicked="scorecardClicked"
      :roundingDigits="0"
    />
    <ScorecardSidepanel
      v-if="scorecardSidepanel && isMonthValid"
      :user-scorecard="userScorecard"
      :obsUser="obsUser"
      :year="year"
      :month="obsMonth"
      @closePanel="closeScorecardPanel"
      @update-month-summary="handleMonthSummaryUpdate"
    />
    <!-- <UserSidepanel
      v-if="userPanelOpen"
      @overlayClicked="userPanelOpen = false"
      :userId="obsUser._id"
    /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Table from "@/components/common/Table";
import axios from "axios";
import ScorecardSidepanel from "@/components/Scorecard/ScorecardSidepanel";
import moment from "moment/moment";

export default {
  name: "UsersContent",
  components: {
    ScorecardSidepanel,
    Table,
  },
  props: ["searchValue", "year"],
  data() {
    return {
      loaded: false,
      usersScorecards: null,
      sortUpdater: 0,
      updateTable: 0,
      originalArr: [],
      allUsers: [],
      filteredUsers: [],
      scorecardSidepanel: false,
      userScorecard: null,
      userScorecards: null,
      obsUser: null,
      obsMonth: null,
      tableFields: [
        {
          fieldValue: this.$t("FirstName"),
          jsonValue: "firstname",
          style: "min-width:120px; max-width:120px; height:52px",
        },
        {
          fieldValue: this.$t("LastName"),
          jsonValue: "lastname",
          style: "min-width:120px; max-width:120px; height:52px",
        },
        ...moment.monthsShort().map((m, i) => {
          return {
            fieldValue: m,
            jsonValue: i + 1 + "",
            style: "min-width:72px; max-width:72px; height:52px",
          };
        }),
        {
          fieldValue: this.$t("Summary"),
          jsonValue: "summary",
          style: "min-width:120px; max-width:120px; height:52px",
        },
      ],
    };
  },
  created() {
    this.setup();
  },
  mounted() {},
  watch: {
    searchValue() {
      const search = this.searchValue.toLowerCase();
      if (search) {
        this.filteredUsers = this.allUsers.filter(
          (item) =>
            item.firstname.toLowerCase().includes(search) ||
            item.lastname.toLowerCase().includes(search)
        );
      } else {
        this.filteredUsers = [...this.allUsers];
      }

      this.sortUpdater++;
      this.updateTable++;
    },
    async year() {
      this.originalArr = [];
      this.filteredUsers = [];
      this.mapUsersScorecardsToTable();
      this.updateTable++;
      await this.fetchUsersScorecards();
    },
    async groupId() {
      if (this.groupId) {
        await this.setup();
        this.updateTable++;
        await this.fetchUsersScorecards();
      }
    },
    activeScorecardGroupFilter: async function () {
      await this.initUsersScoreCards();
      this.mapUsersScorecardsToTable();
      await this.fetchUsersScorecards();
      this.sortUpdater++;
      this.updateTable++;
    },
  },
  computed: {
    ...mapGetters(["user", "users", "groups", "activeScorecardGroupFilter"]),
    selectedFilterUsers() {
      if (!this.activeScorecardGroupFilter) {
        return this.users.filter((user) => user.userStatus === "Active");
      }

      const groupUsers =
        this.activeScorecardGroupFilter.type === "group"
          ? this.groups[this.activeScorecardGroupFilter.value].users
          : null;

      return this.users.reduce((accumulator, currentUser) => {
        if (
          this.activeScorecardGroupFilter.type === "user" &&
          currentUser._id === this.activeScorecardGroupFilter.value
        ) {
          return [currentUser];
        }

        if (currentUser.userStatus !== "Active") {
          return accumulator;
        }

        if (
          this.activeScorecardGroupFilter.type === "group" &&
          groupUsers[currentUser._id]
        ) {
          accumulator.push(currentUser);
        }

        return accumulator;
      }, []);
    },
    isMonthValid() {
      const currentDate = moment();
      const observedDate = moment({
        year: this.year,
        month: this.obsMonth - 1,
      });

      return (
        this.obsMonth > 0 &&
        this.obsMonth <= 12 &&
        observedDate.isSameOrBefore(currentDate, "month")
      );
    },
  },
  methods: {
    ...mapActions(["setUsersScoreCards", "clearUsersScoreCards"]),
    async fetchUsersScorecards() {
      this.loaded = false;
      const selectedUsers = this.selectedFilterUsers;
      let userIds = selectedUsers.map((user) => user._id);
      let body = {
        userIds: userIds,
        year: this.year,
        companyId: this.user.access.company._id,
      };
      try {
        const response = await axios.post(`/api/scorecard/users`, body);
        this.allUsers = response.data;
        this.filteredUsers = [...this.allUsers];
      } catch (error) {
        console.error("Error fetching users scorecards:", error);
      }
      this.loaded = true;
    },
    async setup() {
      this.loaded = false;
      await this.fetchUsersScorecards();
      await this.initUsersScoreCards();
      this.mapUsersScorecardsToTable();
      this.mapFieldsToDataset();
      this.loaded = true;
    },
    closeScorecardPanel(userScoreCard) {
      this.scorecardSidepanel = false;
      this.usersScorecards.find(
        (item) => item._id === this.obsUser._id
      ).scorecards[this.year].months[this.obsMonth] = userScoreCard;
      this.mapUsersScorecardsToTable();
      this.updateTable++;
      this.sortUpdater++;
    },
    async scorecardClicked(userId, field) {
      this.obsMonth = field;
      this.obsUser = this.users.find((user) => user._id === userId);
      this.scorecardSidepanel = true;
      await this.openScorecard(field);
    },
    async openScorecard(month) {
      if (
        (this.user.access.accessLevel === "user" &&
          this.obsUser._id !== this.user._id) ||
        (this.user.access.accessLevel === "group_admin" &&
          !this.obsUser.groups.some((obsGroup) =>
            Object.prototype.hasOwnProperty.call(
              this.user.access.groups,
              obsGroup._id
            )
          ) &&
          this.obsUser._id !== this.user._id)
      ) {
        return;
      }

      try {
        if (!this.userScorecards[this.year].months[month]) throw "error";
      } catch (e) {
        return;
      }
    },
    handleMonthSummaryUpdate(data) {
      const userIndex = this.filteredUsers.findIndex(
        (user) => user.id === data.userId
      );
      if (userIndex !== -1) {
        let user = this.filteredUsers[userIndex];
        const monthKey = data.month.toString();
        user[monthKey] = data.monthSummary;
        this.updateTable++;
      }
    },
    mapUsersScorecardsToTable() {
      if (!this.usersScorecards) return;
      this.originalArr = [];
      for (let i = 0; i < this.selectedFilterUsers.length; i++) {
        const user = this.selectedFilterUsers[i];
        let tableUser = {
          id: user._id,
          firstname: user.firstname,
          lastname: user.lastname,
        };

        const usersScorecard = this.usersScorecards.find(
          (item) => item._id === user._id
        );

        if (
          usersScorecard &&
          usersScorecard.scorecards &&
          usersScorecard.scorecards[this.year]
        ) {
          tableUser["summary"] = usersScorecard.scorecards[this.year].summary;
        }

        if (
          usersScorecard &&
          usersScorecard.scorecards &&
          usersScorecard.scorecards[this.year] &&
          usersScorecard.scorecards[this.year].months
        ) {
          const obsMonths = usersScorecard.scorecards[this.year].months;
          Object.keys(obsMonths).forEach((monthName) => {
            tableUser[monthName] = obsMonths[monthName].summary;
          });
        }

        this.originalArr.push(tableUser);
      }
      // this.filteredUsers = [...this.originalArr];
    },
    getTableFieldsKeys() {
      return this.tableFields.map((field) => field.jsonValue);
    },
    getTableFields() {
      return this.tableFields.map((field) => field.fieldValue);
    },
    mapFieldsToDataset() {
      const tableFieldsKeys = this.getTableFieldsKeys();
      const mappedUsers = this.filteredUsers.map((user) => {
        const mappedUser = { ...user };
        tableFieldsKeys.forEach((jsonKey) => {
          if (!Object.prototype.hasOwnProperty.call(mappedUser, jsonKey)) {
            mappedUser[jsonKey] = null;
          }
        });
        return mappedUser;
      });
      const tableFields = this.getTableFields();

      const obj = {
        fieldsKeys: tableFieldsKeys,
        fields: tableFields,
        dataset: mappedUsers,
      };

      this.setUsersScoreCards(obj);
    },
    async initUsersScoreCards() {
      const usersIds = this.selectedFilterUsers.map((u) => u._id);
      const response = await axios.post(`/api/admin/all/scorecards`, usersIds);
      if (response.status === 200) {
        this.usersScorecards = response.data;
      }
    },
  },
  beforeDestroy() {
    this.clearUsersScoreCards();
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  background-color: #f4f5f7;
  flex-wrap: nowrap;
  flex-direction: column;
  padding-bottom: 10vh;
}
</style>
